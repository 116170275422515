.navbar-nav.sm-collapsible .has-submenu {
    padding-right: 3em
}

.navbar-nav.sm-collapsible .dropdown-item,
.navbar-nav.sm-collapsible .nav-link {
    position: relative
}

.fixed-bottom .navbar-nav:not(.sm-collapsible):not([data-sm-skip]) .dropdown-toggle::after {
    border-top: 0;
    border-bottom: .3em solid
}

.navbar-nav:not(.sm-collapsible):not([data-sm-skip]) .dropdown-menu .dropdown-toggle::after {
    position: absolute;
    top: 50%;
    right: 0;
    width: 0;
    height: 0;
    margin-top: -.3em;
    margin-right: 1em;
    border-top: .3em solid transparent;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.navbar-nav:not(.sm-collapsible) .dropdown-item.has-submenu {
    padding-right: 2em
}

.navbar-nav .scroll-down,
.navbar-nav .scroll-up {
    position: absolute;
    display: none;
    visibility: hidden;
    height: 20px;
    overflow: hidden;
    text-align: center
}

.navbar-nav .scroll-down-arrow,
.navbar-nav .scroll-up-arrow {
    position: absolute;
    top: -2px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    overflow: hidden;
    color: rgba($nav-dropdown-color, 0.75);
    border-top: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid;
    border-left: 7px solid transparent
}

.navbar-nav .scroll-down-arrow {
    top: 6px;
    border-top: 7px solid;
    border-right: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid transparent
}

.navbar-nav.sm-collapsible .dropdown-menu .dropdown-menu {
    margin: .5em
}

.navbar-nav:not([data-sm-skip]) .dropdown-item {
    white-space: normal
}

.navbar-nav:not(.sm-collapsible) .sm-nowrap>li>.dropdown-item {
    white-space: nowrap
}

/*# sourceMappingURL=jquery.smartmenus.bootstrap-4.min.css.map */