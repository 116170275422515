//
// Docs color palette classes
//

@each $color, $value in $colors {
  .bg-#{$color} {
    background-color: #{$value} !important;
  }
  .text-#{$color} {
    color: #{$value} !important;
  }
}

@each $color, $value in $theme-colors {
  .bg-#{$color} {
    background-color: #{$value} !important;
  }
  .text-#{$color} {
    color: #{$value} !important;
  }
}

@each $color, $value in $grays {
  .bg-#{$color} {
    background-color: #{$value} !important;
  }
  .text-#{$color} {
    color: #{$value} !important;
  }
}
